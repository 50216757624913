.accountSumary_parentSection {
  margin-top: 0px !important;
  padding-bottom: 8px;
}
.accountSummary_mainItemSection {
  padding-top: 2px !important;
  font-size: 0.8rem !important;
  height: 162px;
}
.accountSummary_tableHeading {
  vertical-align: top;
}
/* Trainings Styling */
.accountSummary_parent {
  margin: 10px 10px 7px 10px;
  height: 232px;
}
.accountSummary_header {
  width: 100%;
  height: 3rem;
  background: #e0f1f7;
  border-radius: 10px;
  padding-left: 33px;
  padding-top: 10px;
  box-sizing: border-box;
  margin-bottom: unset;
}
.accountSummary_mainHeading {
  font-size: 1.05rem !important;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  padding-left: 0px !important;
}
.accountSummary_red {
  color: #ff0000;
  font: italic normal normal 0.8rem Adobe Clean !important;
}
.accountSummary_headingNoteText {
  text-align: left;
  font: italic normal normal 0.8rem Adobe Clean !important;
  letter-spacing: 0px;
  color: #797979;
}
.accountSummary_trainingsHeadingNoteText {
  text-align: left;
  font: italic normal normal 0.8rem Adobe Clean !important;
  letter-spacing: 0px;
  color: #797979;
  padding: 19px 6px 6px 0px !important;
  left: 5%;
  position: relative;
}
.accountSummary_note {
  font: italic normal normal 0.8rem Adobe Clean !important;
  letter-spacing: 0px;
  color: #000000;
}
.accountSummary_trainingsBottomNote {
  display: block;
  height: 18px;
  bottom: 4px;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  width: 97%;
  font: italic normal normal 0.6rem Adobe Clean !important;
  letter-spacing: 0px;
  color: #000000;
}
.accountSummary_trainingsTable {
  overflow-y: auto;
  height: 107px;
  display: block;
  padding: 10px 0px 10px 13px !important;
}

.accountSummary_column_headers {
  font: normal normal bold 20px/55px Adobe Clean;
  padding: 6px !important;
}
.accountSummary_section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/* Comment Styling */
.accountSummary_commentsParent {
  margin: 10px 10px 7px 10px;
  background-color: #e0f1f7;
  padding-bottom: 2px;
  border-radius: 10px;
  height: 232px;
}
.accountSummary_commentHeader {
  width: 100%;
  height: 48px;
  background: #e0f1f7;
  border-radius: 10px;
  padding-left: 19px;
  padding-top: 12px;
  box-sizing: border-box;
}
.acountSummary_commentBox {
  width: 90%;
  padding: 12px;
  border-radius: 10px 10px 0 10px;
  resize: none !important;
}

/* Roles */
.accountSummary_rolesTrainingParent {
  margin: 10px 10px 7px 10px;
  height: 158px;
}
.accountSummary_rolesTable {
  padding-left: 12px !important;
  padding-right: 12px !important;
  overflow: auto;
  height: 92px;
  display: block;
}

.bar {
  font-size: 40px;
  font-weight: 100;
  color: lightgrey;
  position: relative;
  top: 5px;
  height: 100px;
}
.accountSummary_editIcon {
  text-align: right;
  background-color: white;
  border-radius: 6px;
  font-size: 24px !important;
  cursor: pointer;
}
.accountSummary_rolesRow {
  padding-left: 12px;
  padding-right: 12px;
}

/* Affiliates Section */
.accountSummary_affiliatesParent {
  margin: 10px 10px 7px 10px;
}
.accountSummary_headingSubNoteText {
  line-height: 1.37;
}
.accountSummary_affiliateBar {
  font-size: 24px;
  font-weight: 100;
  color: lightgrey;
  position: relative;
  top: 2px;
}
.accountSummary_affiliateIconStyle {
  text-align: right;
  padding-right: 18px !important;
  padding-top: 20px !important;
}
/* User Details Section */
.accountSummary_userDetailsParent {
  margin: 10px 10px 7px 10px;
}
.userDetailsContainer {
  margin-left: auto;
  margin-right: auto;
  line-height: 1;
}
.accountSummary_userDetailsTable {
  padding-left: 0px !important;
  padding-bottom: 10px !important;
  overflow-y: auto;
  height: 118px;
  display: block;
}
.accountSummary_userDetailsTable tr:first-child {
  display: block;
  margin-top: 10%;
}
.accountSummary_userDetailsTable tr {
  display: block;
  padding: 0px 14px 0px 14px;
}
.accountSummary_userDetailsTable td {
  padding-left: 34px;
}

.firstTableRow {
  margin-top: 8% !important;
  padding-left: 2px !important;
}
.fourthTableRowMargin {
  margin-top: 8% !important;
}
.fourthTableRow {
  padding: 0px 2px 0px 2px !important;
}
.fifthTableRow {
  margin-top: 15% !important;
  padding: 0px 14px 0px 8px;
}
.userDetailsTable1 {
  width: 22%;
}
.userDetailsTable2 {
  width: 19%;
}
.userDetailsTable3 {
  width: 19%;
}
.userDetailsTable4 {
  width: 24%;
}
.userDetailsTable5 {
  width: 13%;
}
.userDetailsSection {
  padding: 12px 14px;
}
.accountSummary_mainItemSection {
  height: auto;
}
.userDetails_body {
  width: 100% !important;
}
.accountSummary_rolesRowsSize {
  height: 1px;
}
.accountSummary_rolesRows {
  top: 8px;
  position: relative;
  display: contents;
}
.accountSummary_crossModalIcon {
  top: 11px;
  left: 95%;
  position: absolute;
  font-size: 35px !important;
  border-radius: 10px;
  cursor: pointer;
  color: grey;
  background-color: white;
  z-index: 99999;
}
.summaryModalParent .userDetailsCard {
  width: 100% !important;
  margin: auto !important;
}
.summaryModalParent .userDetailsCardMain {
  width: 100% !important;
  margin: auto !important;
}
.summaryModalParent .userDetails_grid_item {
  padding: 10px !important;
}
.summaryModalParent .userDetails_grid {
  padding: 0 10px !important;
}
.summaryModalParent .inputTextField {
  width: 100% !important;
  height: 1em !important;
}
.summaryModalParent .dropdownSelectInput {
  width: 100% !important;
}
.summaryModalParent input {
  font-size: 12px !important;
  height: 0.74rem !important;
}
.summaryModalParent .dropdownSelectInput {
  font-size: 12px !important;
  height: 2.46em !important;
}
.summaryModalParent .userDetailsCard_header {
  padding-left: 1rem !important;
}
.summaryModalMenuList {
  font-size: 12px !important;
}
ul:has(> li.summaryModalMenuList) {
  max-height: 7rem !important;
}
.summaryModalButtons {
  right: 16px;
  position: relative;
  padding-top: 10px;
  display: flex;
  justify-content: right;
}

.summaryModalButtons > button {
  margin: 0 5px;
}

.summaryModalParent .workFlowCard {
  margin-top: 0px !important;
  width: 100% !important;
  height: 330px !important;
  overflow: auto !important;
}
.summaryModalParent .workflow_selectUserRoles {
  padding: 0.4rem !important;
  height: 262px !important;
}
.summaryModalParent .workFlow_body {
  height: 196px !important;
  top: 10px;
  position: relative;
}
.summaryModalParent .workFlowCard_header {
  padding-left: 1.2rem !important;
  position: fixed;
  z-index: 9999;
}
.summaryModalParent .workFlow_element {
  padding: 1px !important;
}
.summaryModalParent .workflow_selectUserRolesHead {
  font-size: 14px !important;
  padding: 1rem 1rem 1rem 1rem !important;
  top: 32px;
  position: relative;
}
.summaryModalParent p.rolesCheckboxLabelClass {
  font-size: 12px !important;
  line-height: 12px !important;
}
.summaryModalParent .rolesCheckboxClass > svg {
  font-size: 22px !important;
}
.summaryModalParent .workflow_userRolesSelected {
  margin-top: 28px !important;
}
.summaryModalParent .rolesChipClass {
  height: 28px !important;
}
.summaryModalParent .rolesChipClass > svg {
  font-size: 1.4rem !important;
}
.summaryModalParent .workflow_selectUserRolesAction > Button {
  font-size: 12px !important;
  padding: 0.3rem 0.6rem !important;
}
.summaryModalParent label:has(> .rolesCheckboxClass) {
  max-height: 58px !important;
}
.summaryModalParent .workflow_selectUserRolesAction > Button {
  top: 30px !important;
}

.accountSummary_trainingCompletitionWithPicker{
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0;
}

.accountSummary_trainingCompletionHeading{
  font-size: 0.9rem;
  font-weight: 700;
}

.accountSummary_trainingCompletitionInstruction{
  display: inline-block;
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 500;
  color: #0000ffad;
  padding: 0.5rem 1rem;
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .MuiGrid-grid-md-11 {
    -webkit-flex-basis: 91.666667%;
    -ms-flex-preferred-size: 91.666667%;
    flex-basis: 91.666667%;
    max-width: 91.666667%;
  }
  .userDetailsSection .MuiGrid-item:has(div):nth-child(odd) {
    -webkit-flex-basis: 20%;
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
    max-width: 20%;
  }
  .userDetailsSection .MuiGrid-item:has(div):nth-child(even) {
    -webkit-flex-basis: 29%;
    -ms-flex-preferred-size: 29%;
    flex-basis: 29%;
    max-width: 29%;
  }
  .accountSummary_userDetailsParent,
  .accountSummary_mainItemSection {
    height: auto;
  }
  .userDetailsSection {
    padding-bottom: 10px;
  }
}

.confirmCheckBoxContainer {
  width: 95%;
  display: flex;
  justify-content: right;
}

.approvar_trainingsCheckbox {
  height: 40px;
  border-radius: 6px;
  background-color: #ffeee5;
  /* width:100%; */
  margin-bottom: -8px;
  /* margin-left: 100px; */
  margin-top: 2px;
  padding-top: 0px;
}

.selectedSponserSupervisor {
  margin: 10px 10px 7px 10px;
  height: 100%;
}

.edit_header {
  width: 100%;
  /* height: 40px; */
  height: 3rem;
  background: #ffeee5;
  border-radius: 10px;
  padding-left: 33px;
  padding-top: 10px;
  box-sizing: border-box;
}

.request_commentsParent {
  margin: 10px 10px 7px 10px;
  background-color: #e0f1f7;
  padding-bottom: 2px;
  border-radius: 10px;
  height: 232px;
}

.request_commentHeader {
  width: 100%;
  height: 58px;
  background: #e0f1f7;
  border-radius: 10px;
  padding-left: 33px;
  padding-top: 12px;
  box-sizing: border-box;
}

.approver_commentsParent {
  margin: 10px 10px 7px 10px;
  /* background-color: #ffeee5; */
  background-color: #f9ccb5;
  padding-bottom: 2px;
  border-radius: 10px;
  height: 232px;
}

.approver_commentHeader {
  width: 100%;
  height: 48px;
  /* background: #ffeee5; */
  background: #f9ccb5;
  border-radius: 10px;
  padding-left: 19px;
  padding-top: 12px;
  box-sizing: border-box;
}

.workflow_training_container {
  width: 100%;
  display: flex;
}
.approval_trainingsTable {
  /* padding-left: 13px !important;
    padding-bottom: 10px !important; */
  overflow-y: auto;
  height: 107px;
  display: block;
  padding: 10px 0px 10px 13px !important;
}
.approval_tableHeading {
  vertical-align: top;
}
.approval_mainItemSection {
  height: auto;
}
.approval_rolesProductsParent {
  margin: 10px 10px 7px 10px;
  height: 100%;
}
.approval_red {
  color: #ff0000;
  font: italic normal normal 0.8rem Adobe Clean !important;
}
.approval_headingNoteText {
  /* font-weight: 100;
    font-size: 14px; */
  text-align: left;
  font: italic normal normal 0.8rem Adobe Clean !important;
  letter-spacing: 0px;
  color: #797979;
  padding: 6px 6px 6px 12px !important;
}
.approval_note {
  font: italic normal normal 0.8rem Adobe Clean !important;
  letter-spacing: 0px;
  color: #000000;
}
.acountSummary_commentBox {
  width: 90%;
  padding: 12px;
  border-radius: 10px 10px 0 10px;
}
.approval_commentHeader {
  width: 100%;
  height: 48px;
  background: #e0f1f7;
  border-radius: 10px;
  padding-left: 19px;
  padding-top: 12px;
  box-sizing: border-box;
}
.approval_commentsParent {
  margin: 10px 10px 7px 10px;
  background-color: #e0f1f7;
  padding-bottom: 2px;
  border-radius: 10px;
  height: 232px;
}

.approval_section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.workflow_container {
  width: 60%;
  padding-left: 12px;
}
.userDetailsSection {
  padding: 12px 14px;
}

.training_container {
  width: 40%;
}
.accountSumary_parentSection {
  margin-top: 0px !important;
  padding-bottom: 8px;
}

.editIcon {
  text-align: right;
  background-color: white;
  border-radius: 2px;
  font-size: 24px !important;
  cursor: pointer !important;
  position: relative;
}
.approval_userDetailsParent {
  margin: 10px 10px 7px 10px;
}
.userDetailsContainer {
  margin-left: auto;
  margin-right: auto;
  line-height: 1;
}

.approval_header {
  width: 100%;
  /* height: 40px; */
  height: 3rem;
  background: #e0f1f7;
  border-radius: 10px;
  padding-left: 33px;
  padding-top: 10px;
  box-sizing: border-box;
  margin-bottom: unset;
}
.approval_mainHeading {
  /* font-size: 18px;
      font-weight: bold !important; */
  font-size: 1.05rem !important;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  padding-left: 0px !important;
}

homeContainerCards {
  width: 100%;
  min-height: calc(var(--homeHeight) - 100px);
}

.homeContainerButtons {
  width: 95%;
  padding-top: 10px;
  display: flex;
  justify-content: right;
}

.homeContainerButtons > button {
  margin: 0 5px;
}

.card {
  width: 95%;
  min-height: 58px;
  margin: 1rem auto;
  background-color: #ffffff59;
  border: 1px solid rgba(0, 0, 0, 0.082);
  border-radius: 10px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.082);
}

.customModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  min-height: 200px;
  border-radius: 10px;
  background-color: white;
}

.searchUser_Main{
  max-width: 85rem;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 90vh;
}

.searchUser_imageWrapper {
  padding: 0.3rem;
  min-height: 90vh;
}

.searchUser_mainImage {
  width: 100%;
  height: 100%;
}

.searchUser_welcomeSearchWrapper {
  padding: 3rem 2rem 0rem 3rem;
  min-height: 90vh;
}

.searchUser_searchWrapper {
  padding: 2rem 4rem;
  margin-top: 1.5rem;
  background-image: linear-gradient(
    to right,
    #ffc5a00d 0%,
    #ffdcc51a 31%,
    #ffdcc51a 73%,
    #ffc5a00d 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.searchUser_welcomeHeadline {
  font-size: 1.56rem;
  font-weight: bold;
  margin: 0 0 0.5rem 0;
}

.searchUser_welcomeText {
  color: #6a6a6a;
  text-align: left;
  letter-spacing: 0.22px;
  font-size: 16px;
  line-height: 1.2;
}

.searchUser_searchText {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  margin: 1rem 0;
}

.searchUser_textFieldWithButton {
  display: flex;
}

.searchUser_textField {
  width: 28rem;
  font-style: italic;
  color: #9a9898;
  text-transform: capitalize;
  background: #fff;
}

.searchUser_searchButton {
  /* background-color: #ffc5a0cc !important; */
  background-color: #edaa80 !important;
  border-radius: 0 8px 8px 0 !important;
  box-shadow: none !important;
  min-width: 3rem !important;
  cursor: pointer;
}

.searchUser_searchButtonDisabled {
  color: #00000042 !important;
  box-shadow: none !important;
  border-radius: 0 8px 8px 0 !important;
  background-color: #0000001e !important;
  min-width: 3rem !important;
  /* cursor: not-allowed; */
}

.searchUser_searchButton:hover {
  /* background-color: #ffc5a0 !important; */
  background-color: #d59973 !important;
  box-shadow: none;
}

.searchUser_crossIcon{
  font-size: 2rem !important;
}

.searchUser_nonOnboardedLink {
  font-style: italic !important;
  padding: 1rem;
  color: #01a0e0 !important;
  cursor: pointer;
  text-align: center;
}


.searchUser_userList{
  max-height: 180px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.searchUser_userList::-webkit-scrollbar{
  width: 12px;
}

.searchUser_userList::-webkit-scrollbar-thumb{
  border-radius: 8px;
  height: 84px;
  background: #ffc5a0cc;
}

.searchUser_userList::-webkit-scrollbar-track{
  border-radius: 8px;
  background: #eee;
}
.loader-container, .error-container{
  width: 31.2rem;
  text-align: center;
  padding: 10px 0;
}
.loader-container svg{
  color:#ffcfb2;
}

.error-text{
  color: #ff0000da;
}

.searchUser_instructionText{
  font-size: 14px;
  font-style: italic;
  color: #6a6a6a;
  line-height: 1.2;
}

.searchUser_adminButtonWrapper{
 display:flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 padding: 10px;
}

.searchUser_adminButton{
  text-transform: capitalize !important;
  padding: 0.5rem 1rem !important;
  /* background-color: #ffc5a0 !important; */
  background-color: #edaa80 !important;
  box-shadow: none !important;
  cursor: pointer;
}

.searchUser_adminButton:hover{
  /* background-color: #edaa80 !important;  */
  background-color: #d59973 !important;
}
.adminMainArticle {
    width: calc(100% - 250px);
    background-color: #3b979629;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 94vh;
    min-height: 94vh;
}

/* .affiliateTableContainer > div {
  border-radius: 10px 10px 0px 0px !important;
} */

.affiliateContainer {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  padding: 10px 20px;
}
.editAffiliate {
  height: 170px;
  padding: 10px;
}

.affiliateOverflow {
  overflow: auto;
  height: 100px;
}

.affiliateTableContainer table td svg.clickable {
  fill: rgb(128, 128, 128);
  width: 0.7em;
  height: 0.7em;
}
@media only screen and (max-width: 930px) {
  .fitermodel-affiliate {
    width: 60% !important;
    height: 53% !important;
  }
}
.adminAddAffliateCardHeader {
  width: 100%;
  height: 2.8rem;
  /* background: #ffeee5; */
  background: #f9ccb5;
  font-size: 0.95rem;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.affiliate_crossModalIcon {
  top: 11px;
  left: 95%;
  position: absolute;
  font-size: 35px !important;
  border-radius: 10px;
  cursor: pointer;
  color: grey;
  background-color: white;
  z-index: 99999;
}

.modalAffiliateFooter {
  display: flex;
  justify-content: right;
  align-items: flex-end;
  /* padding-left: 200px; */
  /* padding-right: 0px; */
}

.modalAffiliateButtons {
  right: 40px;
  position: relative;
  padding-top: 10px;
  display: flex;
  justify-content: right;
}

.modalAffiliateButtons > button {
  margin: 0 5px;
}

.adminAddAffiliateCardFormLineOne,
.adminAddAffiliateCardFormLineTwo {
  width: 100%;
  height: 60px;

  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.adminAddAffiliateCardFormInputLabel {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 700;
}

.dropdownSelectInput {
  width: 18rem;
  /* padding: 0.5rem 0 !important; */
}

.adminAddAffiliateCardFormInputLabel {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 700;
}

.affliateTableSearch {
  width: 100%;
  height: 42px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filterContainerFilter {
  background-color: #ffb48d;
  font-size: 30px;
  border-radius: 5px;
  color: white;
  padding: 5px;
  box-sizing: border-box;
  font-size: 19px;
  margin-right: 5px;
  width: 40px;
  box-shadow: 0px 1px 1px gray;
  cursor: pointer;
}

.filterContainerImport {
  background-color: #ffb48d;
  font-size: 30px;
  border-radius: 5px;
  color: white;
  padding: 5px;
  box-sizing: border-box;
  font-size: 19px;
}

.filterContainerImport > span {
  position: relative;
  top: -4px;
  font-size: 17px;
  margin-left: 4px;
}
#editDelete {
  width: auto;
}
.filterContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.groupsContainer {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  padding: 10px 20px;
}
.groupTableSearchContainer {
  display: flex;
  justify-content: space-between;
}
.searchIcon {
  font-size: 2rem !important;
}
.affiliateTableContainer {
  width: 100%;
  /* height: 100%; 
  max-height: 75vh;*/
  /* height: calc(100% - 200px); */
  /* border: 1px solid green; */
  padding-top: 8px;
  box-sizing: border-box;
}

/* .groupModalParent .rolesCheckboxClass > svg {
  font-size: 22px !important;
}
.group_searchButton {
  background-color: #ffc5a0cc !important;
  border-radius: 0 8px 8px 0 !important;
  box-shadow: none !important;
  min-width: 3rem !important;
  cursor: pointer;
} */

/* .groupModalParent label:has(> .rolesCheckboxClass) {
  height: 20px !important;
}

.groupModalParent p.rolesCheckboxLabelClass {
  font-size: 12px !important;
} */

.affiliateTableHeader {
  width: 100%;
  height: 2.5rem;
  background: #f9ccb5;
  font-size: 1.05rem;
  font-weight: bold;
  border-radius: 10px 10px 0px 0px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.affiliateTableSectionContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
}

.affiliateTableSection {
  padding: 10px;
  width: 98%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.affiliateTableSection table td svg.clickable {
  fill: rgb(128, 128, 128);
  width: 0.7em;
  height: 0.7em;
}
@media only screen and (max-width: 930px) {
  .fitermodel-group {
    width: 70% !important;
    height: 75% !important;
  }
}

.adminAddAffliateCardFormLineOne,
.adminAddAffliateCardFormLineTwo {
  width: 100%;
  height: 60px;

  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

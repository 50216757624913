.adminMainSection {
    width: 250px;
    box-sizing: content-box;
}

.adminMainSectionArrow {
    position: relative;
    top: 4px;
}

.borderBottom {
    border-bottom: 1px solid #00000038;
}

.adminMainSectionCategory {
    padding-left: 48px;
    height: 31px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.adminMainSectionCategorySelected{
    background-color: #f9ccb5; /*#f79b6c;*/
    font-weight: bold;
}

.adminMainSectionCategory:hover {
    background-color: #f9ccb5;
}

.adminMainSectionInnerCategory {
    padding-left: 60px;
    height: 31px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.adminMainSectionInnerCategory:hover {
    background-color: #f9ccb5;
}
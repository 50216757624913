/* WorkFlow */
.workFlowCard {
  width: 95%;
  margin: 0 auto;
  margin-top: 15px;
  background-color: #ffffff59;
  border: 1px solid rgba(0, 0, 0, 0.082);
  border-radius: 10px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.082);
}
.workFlowCard_header {
  width: 100%;
  height: 2.5rem;
  /* background: #ffeee5; */
  background: #f9ccb5;
  font-size: 1.05rem;
  font-weight: bold;
  border-radius: 10px 10px 0px 0px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.workflow_headWithSelection {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.workFlow_body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.workFlow_element {
  padding: 0rem 0.8rem;
}

.workFlow_selected {
  /* border: 1px solid red; */
  padding: 0.5rem 2rem;
  font-size: 0.6rem;
  background: white;
  border-radius: 0.5rem;
  font-style: italic;
  font-weight: lighter;
}
.workflow_selectUserRolesParent {
  margin-bottom: 0.5rem;
}

.workflow_selectUserRolesHead {
  display: inline-block;
  font-size: 1.05rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  padding: 0 2rem;
}

.workflow_selectUserRoles {
  padding: 1rem;
}

/* Workflow Result*/

.workflowResult_section {
  display: block;
  width: 95%;
  margin: 0 auto;
}

.workflowResult {
  width: 95%;
  margin: 1rem auto;
}

.workflowResult_header {
}

.workflowResult_element {
  margin: 0.5rem;
}
.workflowResult_elementHeading {
  display: inline-block;
  font-size: 0.95rem;
  font-weight: bolder;
  width: 35%;
}

.workflowResult_elementContent {
  font-size: 0.95rem;
  display: inline-block;
  font-weight: normal;
}

.workflow_userRolesSelected {
  padding-top: 20px;
}
.workflow_selectUserRoles .MuiGrid-container {
  margin-top: 5px;
}

.workflow_rolesContainer {
  overflow-y: auto;
}

.workflow_arrowContainer {
  width: 2%;
  display: flex;
  margin: 0.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.workflow_arrowIcon {
  cursor: pointer;
  color: #f8ac85;
}

.workFlow_body .workflow_rolesContainer {
  max-height: 200px;
  overflow-y: auto;
}

.inputTextField {
  width: 100% !important;
  padding: 0.5rem 0 !important;
  /* font-size: 0.9rem !important; */
  /* height: 1.2rem; */
}

.displayLabelTextField {
  display: block;
  font-size: 0.8rem;
  font-weight: 900;
}

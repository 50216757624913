.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: calc(100vh - 150px);
    font-family: Sen, sans-serif;
}
.glitch {
    font-size: 4.25rem;
    color: #D52B1E;
    font-weight: 400;
    text-transform: capitalize;
    transform: scale3d(1,1,1);
};
.errDetails {
    margin: 2.5rem 0;
    font-size: 1.65rem;
    font-weight: 400;
}
.errHelp {
    font-size: 1.15rem;
    color: #888;
}
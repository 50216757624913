.reassignAndTerminate_recordTable {
  border-spacing: 0;
  width: 100%;
  /* height: 100%; */
  overflow: scroll;
}

.terminateTableSectionContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
}

.terminateTableContainer {
  fill: rgb(128, 128, 128) !important;
  /* width: 0.7em !important; */
  /* height: 0.7em !important; */
}

.terminateTableSection {
  padding: 10px;
  width: 98%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.terminateTableHeader {
  width: 100%;
  height: 2.5rem;
  background: #f9ccb5; /*#ffeee5;*/
  font-size: 1.05rem;
  font-weight: bold;
  border-radius: 10px 10px 0px 0px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

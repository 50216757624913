.footer {
    width: 100%;
    height: 100%;
    padding: 0px 48px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 13px;
}

.footerTermContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    display: none !important;
}

.footerTerm > span {
    color: #00918c;
    margin-right: 10px;
    border-bottom: 1px solid;
    font-weight: 500;
}

.footerDivider {
    border: 1px solid #00918c;
    height: calc(100% - 14px);
    margin: 8px;
    display: none !important;
}

.footerCopyRightMsg{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
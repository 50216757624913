.reassignAndTerminate_tableHeader{
    position: sticky;
    top: 0;
}

.reassignAndTerminate_tableHeadRow{
    background-color: #f9ccb5; /*#ffeee5;*/
    box-sizing: border-box;
    padding:0.5rem;
    text-align: left;
    font-size: 12px;
    border-right: 1px solid #80808040;
    cursor: pointer;
}
/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #555; 
}
.clickable {
    cursor: pointer;
}

.homeContainer {
    /*--homeHeight: calc(100vh - 80px);
    --homeWidth: calc(100vw - 3px);*/
    width: var(--homeWidth);
    min-height: var(--homeHeight);
    padding: 11px 20px;
    background: "#fafafa";
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pageTitleContainer {
    width: 95%;
    height: 51px;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pageTitleText {
    display: flex;
    flex-direction: column;
}

.pageTitleHeader {
    font-size: 20px;
    font-weight: bold;
}

.pageTitleNavigator {
    margin-top: 1px;
    font-size: 12px;
    color: #000000;
    cursor: context-menu;
}

.pageTitleIcon{
    display: flex;
    flex-direction: column;
}

.pageTitleIconBox{
    display: flex;
    flex-direction: row;
    margin-left: 16px;
}

.pageNumberTag {
    border-radius: 4px;
    width: 22px;
    height: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 900;

    border: 1.5px solid #38ABE2;
    background-color: #ffffff;
    color: #38ABE2;
}

.pageNumberTagSelected {
    background-color: #38ABE2 !important;
    color: #ffffff !important;
}

.pageNumberTagCompleted {
    border: 1.5px solid #00AE43 !important;
    background-color: #ffffff !important;
    color: #00AE43 !important;
}

.horizontalConnectorOrange {
    position: relative;
    top: 11px;
    width: 60px;
    height: 0px;
    border: 1px solid orange;
}

.horizontalConnectorGray {
    position: relative;
    top: 11px;
    width: 60px;
    height: 0px;
    border: 1px solid gray;
}

.pageTitleIconText > span {
    font-size: 12.2px;
    font-weight: 790;
}

.pageTitleIconTextSecond {
    margin-left: 75px;
}

.pageTitleIconTextThird {
    margin-left: 35px;
}

.homeContainerCards {
    width: 100%;
    min-height: calc(var(--homeHeight) - 100px);
}

.homeContainerButtons {
    width: 95%;
    padding-top: 10px;
    display: flex;
    justify-content: right;
}

.homeContainerButtons > button {
    margin: 0 5px;
}

.card {
    width: 95%;
    min-height: 58px;
    margin: 1rem auto;
    background-color: #ffffff59;
    border: 1px solid rgba(0, 0, 0, 0.082);
    border-radius: 10px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.082);
}
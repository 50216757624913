.adminDashboardTableSearch {
  width: 100%;
  height: 42px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filterContainerFilter {
  background-color: #ffb48d;
  font-size: 30px;
  border-radius: 5px;
  color: white;
  padding: 5px;
  box-sizing: border-box;
  font-size: 19px;
  margin-right: 5px;
  width: 40px;
  box-shadow: 0px 1px 1px gray;
  cursor: pointer;
}

.filterContainerImport {
  background-color: #ffb48d;
  font-size: 30px;
  border-radius: 5px;
  color: white;
  padding: 5px;
  box-sizing: border-box;
  font-size: 19px;
}

.filterContainerImport > span {
  position: relative;
  top: -4px;
  font-size: 17px;
  margin-left: 4px;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* Modal CSS */
.accountSummary_crossModalIcon {
  top: 11px;
  left: 95%;
  position: absolute;
  font-size: 35px !important;
  border-radius: 10px;
  cursor: pointer;
  color: grey;
  background-color: white;
  z-index: 99999;
}
.summaryModalButtons {
  right: 16px;
  position: relative;
  padding-top: 10px;
  display: flex;
  justify-content: right;
}

.summaryModalButtons > button {
  margin: 0 5px;
}

.filterContentHeader {
  /* background-color: #ffeee5; */
  background-color: #f9ccb5;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  font-size: 17px;
  font-weight: 700;
  border-radius: 10px 10px 0 0;
}

.filterContentContent > div {
  border-bottom: 1px solid #0000003d;
  padding: 12px;
  font-size: 14px;
  font-weight: 700;
}

.filterContentContentSection {
  font-size: 14px;
  font-weight: 500;
  margin: 5px;
}

.filterContentContentSection > div {
  margin-right: 4px;
  margin-top: 2px;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(0 0 0 / 89%) !important;
}

@media (min-width: 600px){
    .css-1lu13wv-MuiToolbar-root, .css-1iaqa93{
        min-height: 12px !important;
    }
}
@media (min-width: 901px) and (max-width: 1300px){
    body{
        zoom:0.9
    }
}

.adminNavigationContainer{
    width: 100%;
    height: 20px;
}

.adminFooterContainer {
    width: 100%;
    height: 29px;
}

.adminMainContainer{
    width: 100%;
}
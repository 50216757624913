.groupsContainer {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  padding: 10px 20px;
}
.groupTableSearchContainer {
  display: flex;
  justify-content: space-between;
}
.searchIcon {
  font-size: 2rem !important;
}
.groupTableContainer {
  width: 100%;
  /* height: 100%; 
  max-height: 75vh;*/
  /* height: calc(100% - 200px); */
  /* border: 1px solid green; */
  padding-top: 8px;
  box-sizing: border-box;
}

.groupModalParent .rolesCheckboxClass > svg {
  font-size: 22px !important;
}
.group_searchButton {
  /* background-color: #ffc5a0cc !important; */
  background-color: #edaa80 !important;
  border-radius: 0 8px 8px 0 !important;
  box-shadow: none !important;
  min-width: 3rem !important;
  cursor: pointer;
}

.group_searchButton:hover{
  background-color: #d59973 !important;
}

.groupModalParent label:has(> .rolesCheckboxClass) {
  height: 20px !important;
}

.groupModalParent p.rolesCheckboxLabelClass {
  font-size: 12px !important;
}

.groupTableHeader {
  width: 100%;
  height: 2.5rem;
  background: #f9ccb5; /*#ffeee5;*/
  font-size: 1.05rem;
  font-weight: bold;
  border-radius: 10px 10px 0px 0px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.groupTableSectionContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
}

.groupTableSection {
  padding: 10px;
  width: 98%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.groupTableSection table td svg.clickable {
  fill: rgb(128, 128, 128);
  width: 0.7em;
  height: 0.7em;
}
@media only screen and (max-width: 930px) {
  .fitermodel-group {
    width: 70% !important;
    height: 75% !important;
  }
}

.adminDashboardTableBox {
    width: 100%;
    height: calc(100% - 162px);
    /* border: 1px solid green; */
    padding-top: 5px;
    box-sizing: border-box;
}

.filterSortIcon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    width: 13px;
}